import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from 'components/header';
import Footer from 'components/footer';

import './normalise.css';
import './base.scss';
import './typography.scss';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div id="page" className="page">
      <Header siteTitle={data.site.siteMetadata.title} />
      <main id="main" className="main">
        {children}
      </main>
      <Footer siteTitle={data.site.siteMetadata.title} />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
