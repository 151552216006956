/**
 * Menu configuration
 */

const footerMenu = [
  {
    title: 'Privacy policy',
    url: '/privacy',
  },
  {
    title: 'Cookies',
    url: '/cookies',
  },
];

export default footerMenu;
