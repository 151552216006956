import React from 'react';
import { Link } from 'gatsby';
import MenuItems from 'data/menu/footer';

import styles from './styles.module.scss';

const MenuFooter = () => (
  <nav className={styles.navigation}>
    <ul>
      {MenuItems.map((menuItem) => (
        <li key={menuItem.url}>
          <Link
            to={menuItem.url}
            activeClassName={styles.active}
          >
            {menuItem.title}
          </Link>
        </li>
      ))}
    </ul>
  </nav>
);

export default MenuFooter;
