/**
 * Menu configuration
 */

const mainMenu = [
  {
    title: 'About',
    url: '/#about',
  },
  {
    title: 'Services',
    url: '/services',
  },
  {
    title: 'Contact',
    url: '/contact',
  },
];

export default mainMenu;
