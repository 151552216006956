import React from 'react';
import PropTypes from 'prop-types';

import containerStyles from './container.module.scss';

const Container = ({ children }) => (
  <>
    <section className={`container ${containerStyles.container}`}>
      {children}
    </section>
  </>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
