import React from 'react';
import { Link } from 'gatsby';
import { stack as BurgerMenu } from 'react-burger-menu';
import MainMenuItems from 'data/menu/main';
import styles from './styles.module.scss';

const MenuCollapsable = () => (
  <nav className={styles.navigation}>
    <BurgerMenu
      right
      outerContainerId="page"
      pageWrapId="main"
      className={styles.menuWrapper}
      burgerButtonClassName={styles.burgerButton}
      burgerBarClassName={styles.burgerBars}
      crossButtonClassName={styles.crossButton}
      crossClassName={styles.cross}
      menuClassName={styles.menu}
      morphShapeClassName={styles.morphShape}
      itemListClassName={styles.itemList}
      itemClassName={styles.item}
      overlayClassName={styles.overlay}
    >
      <ul>
        {MainMenuItems.map((menuItem) => (
          <li key={menuItem.url}>
            <Link
              to={menuItem.url}
              activeClassName={styles.active}
            >
              {menuItem.title}
            </Link>
          </li>
        ))}
      </ul>
    </BurgerMenu>
  </nav>
);

export default MenuCollapsable;
