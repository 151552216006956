import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import Container from 'components/container';
import MenuCollapsable from 'components/menu/collapsable';
import MenuStatic from 'components/menu/static';

import styles from './header.module.scss';

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.svg" }) {
        childImageSharp {
          fixed(width: 125, height: 125) {
            ...GatsbyImageSharpFixed
          }
        }
        extension
        publicURL
      }
    }
  `);

  return (
    <header className={styles.header}>
      <MenuCollapsable />
      <Container>
        <div className={styles.headerItems}>
          <Link
            className={styles.logo}
            to="/"
            aria-label={siteTitle}
          >
            {/* svg support */}
            {(!data.file.childImageSharp && data.file.extension === 'svg')
              ? <img src={data.file.publicURL} alt={siteTitle} />
              : <Img fixed={data.file.childImageSharp.fixed} />}
          </Link>
          <MenuStatic />
        </div>
      </Container>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
