import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import Container from 'components/container';
import MenuFooter from '../menu/footer';

import styles from './footer.module.scss';

const Footer = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo-light.svg" }) {
        childImageSharp {
          fixed(width: 125, height: 125) {
            ...GatsbyImageSharpFixed
          }
        }
        extension
        publicURL
      }
    }
  `);

  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.top}>
          <MenuFooter />
          <div className={styles.copyright}>
            {`${siteTitle} © ${new Date().getFullYear()}`}
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.signature}>
            Developed by
            <a target="_blank" rel="noreferrer" href="https://angelwebmedia.com">Angel Web Media</a>
          </div>
          {/* svg support */}
          {(!data.file.childImageSharp && data.file.extension === 'svg')
            ? <img src={data.file.publicURL} alt={siteTitle} />
            : <Img fixed={data.file.childImageSharp.fixed} />}

        </div>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: '',
};

export default Footer;
